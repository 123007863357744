// Scroll Down

$('.scroll-btn').on("click", function() {
  var percentageToScroll = 100;
  var height = $('.banner-item').innerHeight();
		console.log($('.banner-item').innerHeight());
  var scrollAmount = (height * percentageToScroll / 100) - 90;
	console.log('click');
  $("html, body").animate({
    scrollTop: scrollAmount
  }, 1000);
});