$(function() {
  
    scrollFunction();
  
    // Side Menu Control
    var myDir = ($("html").attr('dir') == 'rtl' ? 'right' : 'left' );
    var mySlidebars = new $.slidebars();
    $('.mobile-menu-btn').on('click', function() {
        mySlidebars.slidebars.toggle(myDir);
    });
  
    $(window).on("scroll",scrollFunction);
  
    function scrollFunction() {
      if ($(window).scrollTop() > 20) {
        $(".main-header").addClass("scrolling");
      } else {
        $(".main-header").removeClass("scrolling");
      }
    }
  });
  