$(function(){
  $(".fileuplaodcv").AjaxFileUpload({
    onChange: function(filename){
      $(".btn-file").addClass("uploading");
    },
    onComplete: function(filename, response) {
        $(".fileuplaodcvupdate").val(response)
        $(".txt_up").text(filename);
        $(".btn-file").removeClass("uploading");
      }
  });
});